import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchGetHttp1, fetchGetHttp2 } from "../Utils/FetchApi";
import tagManagerEvents from "../Utils/GoogleTagManager";
import { encode as base64_encode } from "base-64";
import ApiNames from "../Constants/ApiNames";
import Awareness from "../Components/Awareness";
// import Khawb from "../Components/Khawb";
import TagManager from "react-gtm-module";

let adNetwork = "";
export const AwarenessScreen = (props) => {
  const search = useLocation().search;
  const utm_source = new URLSearchParams(search).get("utm_source");

  const navigate = useNavigate();
  const [subUtm, setSubUtm] = useState(utm_source);

  useEffect(() => {
    if (props.token) {
      checkUtm();
      authFlow();
    }
  }, [props.token]);

  useEffect(() => {
    setTimeout(() => {
      gtmInitialization();
    }, 1000);
  }, []);
  const gtmInitialization = () => {
    const tagManagerArgs = {
      gtmId: "GTM-K58VKLX",
    };
    TagManager.initialize(tagManagerArgs);
  };

  const checkUtm = async () => {
    try {
      let response = await fetchGetHttp1(ApiNames.utm, props.token);
      if (response.status === 200) {
        {
          response.response.map((e) => {
            if (utm_source === e.sf_utm) {
              // setSubUtm("econceptions");
              adNetwork = e.ad_network;
            }
          });
        }
        console.warn("updated value::", adNetwork);
      }
    } catch (err) {
      console.log("UTM failed: " + err);
    }
  };

  const authFlow = async () => {
    try {
      let response = await fetchGetHttp1(
        ApiNames.GetAwareness + "?utm_source=" + utm_source,
        props.token
      );
      if (response.status === 200) {
        if (response.response.code === "0" || response.response.code === "2") {
          if (
            adNetwork.toLowerCase() === "tiktok" ||
            adNetwork.toLowerCase() === "tik tok"
          ) {
            console.warn("aaa");
            tagManagerEvents("subscribe", "econTikTok");
          } else if (adNetwork.toLowerCase() === "google") {
            console.warn("bbb");
            tagManagerEvents("subscribe", "econceptions");
          } else if (
            utm_source?.toLowerCase() === "jazz" ||
            utm_source?.toLowerCase() === "jazzw" ||
            utm_source?.toLowerCase() === "jazzb"
          ) {
            // ReactPixel.track("Subscribe");
            tagManagerEvents("subscribe", subUtm);
          } else {
            console.warn("ccc");
            tagManagerEvents("subscribe", subUtm);
          }
          fcmToken(response.response.msisdn);
        } else {
          navigateToHome();
        }
      } else {
        navigateToHome();
      }
    } catch (err) {
      navigateToHome();
    }
  };

  // const headerEnrichment = (response) => {
  //   try {
  //     if (response.isSpecialFlow === true) {
  //       if (response.msisdn && response.otp) {
  //         subscriber(
  //           response.isSpecialFlow && response.fastFlow
  //             ? ApiNames.subscribeFastFlow
  //             : ApiNames.subscribe,
  //           response.msisdn,
  //           response.package
  //         );
  //       } else {
  //         navigate("/home");
  //       }
  //     } else {
  //       navigate("/home");
  //     }
  //   } catch (error) {
  //     navigate("/home");
  //   }
  // };

  // const subscriber = async (apiName, phoneNumber, userPackage) => {
  //   try {
  //     let response = await fetchGetHttp1(
  //       apiName +
  //         "?msisdn=" +
  //         phoneNumber +
  //         "&utm_source=" +
  //         utm_source +
  //         "&subPackage=" +
  //         userPackage,
  //       props.token
  //     );
  //     if (response.status === 200) {
  //       if (response.response.code === "0" || response.response.code === "2") {
  //         if (
  //           adNetwork.toLowerCase() === "tiktok" ||
  //           adNetwork.toLowerCase() === "tik tok"
  //         ) {
  //           console.warn("aaa");
  //           tagManagerEvents("subscribe", "econTikTok");
  //         } else if (adNetwork.toLowerCase() === "google") {
  //           console.warn("bbb");
  //           tagManagerEvents("subscribe", "econceptions");
  //         } else if (
  //           utm_source?.toLowerCase() === "jazz" ||
  //           utm_source?.toLowerCase() === "jazzw" ||
  //           utm_source?.toLowerCase() === "jazzb"
  //         ) {
  //           // ReactPixel.track("Subscribe");
  //           tagManagerEvents("subscribe", subUtm);
  //         } else {
  //           console.warn("ccc");
  //           tagManagerEvents("subscribe", subUtm);
  //         }

  //         fcmToken(phoneNumber);
  //       } else {
  //         navigate("/home");
  //       }
  //     } else {
  //       navigate("/home");
  //     }
  //   } catch (err) {
  //     console.log("Subscribe Failed: " + err);
  //     navigate("/home");
  //   }
  // };

  const fcmToken = async (phoneNumber) => {
    try {
      let response = await fetchGetHttp2(
        ApiNames.fcmToken + "?fcm_token=" + phoneNumber
      );

      if (response.status === 200) {
        let url = window.location.origin;
        let encodedMsisdn = base64_encode(phoneNumber);
        let encodedSubId = base64_encode(response.response.subscriber_id);
        url = `${url}/home?ndsism=${encodedMsisdn}&subid=${encodedSubId}`;
        if (url.includes("http:")) {
          url = url.replace("http", "https");
        }
        window.location.replace(url);
      } else {
        navigateToHome();
      }
    } catch (err) {
      console.log("Get fcmToken failed: " + err);
      navigateToHome();
    }
  };

  const navigateToHome = () => {
    let url = window.location.origin;
    url = `${url}/home`;
    if (url.includes("http:")) {
      url = url.replace("http", "https");
    }
    if (props.flag === 1) {
      window.location.replace(url);
    }
  };

  return <Awareness />;
};
