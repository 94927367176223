const ApiNames = {
  allStars: "/api/star",
  checkoperator: "/api/checkoperator",
  sendOTP: "/api/subscriber/sendWebOTP",
  verifyOTP: "/api/subscriber/verifyWebOTP",
  loginSendOtp: "/api/login/sendWebOTP",
  loginVerifyOtp: "/api/login/verifyWebOTP",
  checkUser: "/api/checkUser",
  subscribe: "/api/v2/subscribeWeb",
  subscribeFastFlow: "/api/v2/ffSubscribeWeb",
  login: "/api/v2/loginWeb",
  getZodiacSign: "/api/getZodiacSign",
  setZodiacSign: "/api/setZodiacSign",
  fcmToken: "/api/token/fcm",
  dashboard: "/api/dashboard",
  horoscope: "/api/horoscope",
  randomDream: "/api/common/dreams-categories",
  randomFortuneCookie: "/api/fortune/random",
  nameMeaning: "/api/namemeaning",
  unSubscribe: "/api/unsubscribeWeb",
  commonDreams: "/api/common/dreams-categories",
  myDreams: "/api/dream",
  commonDreamId: "/api/common/dreams-categories",
  createDream: "/api/dream/create",
  deleteDream: "/api/dream/delete",
  allVideos: "/api/videos",
  createProfile: "/api/subscriber/profile/create",
  showProfile: "/api/subscriber/profile",
  loveCompability: "/api/love/compatibility/page",
  celebrityCompability: "/api/celebrity/compatibility",
  nameCompability: "/api/namecompatibility",
  celebrityList: "/api/celebrity",
  appVersion: "/api/version",
  enrichment: "/api/enrichment",
  matchPridiction: "/api/teams",
  generateJWT: "/api/accessWeb",
  GetFlow: "/api/getFlow",
  GetAwareness:"/api/v2/awareness",
  enrichment: "/api/enrichment",
  utm: "/api/utms",
};

export default ApiNames;
