
export const fetchGet = async (apiName) => {
    let response = await fetch(`${process.env.REACT_APP_Base_URL}${apiName}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "CIPHER-APP-KEY": process.env.REACT_APP_App_Key,
        },
      })
  let jsonResponse = await response.json();

  return jsonResponse;
};




export const fetchPost = async (apiName) => {
  let url = process.env.REACT_APP_Base_URL;
  if (window.location.protocol === "http:") {
    url = url.replace("https", "http");
  }
  let response = await fetch(`${url}${apiName}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      "username": process.env.REACT_APP_UserName,
      "password": process.env.REACT_APP_Password
  }),
  });
  
  let jsonResponse = await response.json();

  return jsonResponse;
};


export const fetchGetAuth = async (apiName,token) => {
  let response = await fetch(`${process.env.REACT_APP_Base_URL}${apiName}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });
  let jsonResponse = await response.json();
  return jsonResponse;
}

export const fetchGetFlow = async (apiName,token) => {
let url =process.env.REACT_APP_Base_URL
let baseUrl=url.replace("https", "http"); 
 let response = await fetch(`${baseUrl}${apiName}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });
  let jsonResponse = await response.json();
  return jsonResponse;
}
export const fetchGetHttp1 = async (apiName,token) => {
  let url =process.env.REACT_APP_Base_URL
  let baseUrl=url.replace("https", "http"); 
   let response = await fetch(`${baseUrl}${apiName}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    let jsonResponse = await response.json();
    return jsonResponse;
  }

  export const fetchGetHttp2 = async (apiName,token) => {
    let url =process.env.REACT_APP_Base_URL
    let baseUrl=url.replace("https", "http"); 
     let response = await fetch(`${baseUrl}${apiName}`, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          "CIPHER-APP-KEY": process.env.REACT_APP_App_Key,
        },
      });
      let jsonResponse = await response.json();
      return jsonResponse;
    }


// export const fetchGetEnrishment = async (apiName) => {

// let url =process.env.REACT_APP_Base_URL
// let baseUrl=url.replace("https", "http"); 
//  let response = await fetch(`${baseUrl}${apiName}`, {
//     method: "GET",
//     headers: {
//       'Content-Type': 'application/json',
//       "CIPHER-APP-KEY": process.env.REACT_APP_App_Key,
//     },
//   });
//   let jsonResponse = await response.json();
//   return jsonResponse;
// }





