import React from "react";
import "../StyleSheets/awareness.css";
import MainImg from "../Assets/MainImg.webp";
const Awareness = () => {
  return (
    <div className="awareness-main-bg">
      <div className="main__content">
            <img src={MainImg} width={400} height={700} alt="awearness main img" />
      </div>
    </div>
  );
};

export default Awareness;
